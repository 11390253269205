import Component from '../../../assets/scripts/modules/component'
import isTouchDevice from '../../../assets/scripts/utilities/is-touch-device'

export default class HomepageTweenerComponent extends Component {
  init () {
    this.timeout = null
    history.scrollRestoration = 'manual' // Force scrolling to top on load

    this.slides = [...this.element.querySelectorAll('.homepage-tweener__slide')]

    this.initPlusVisual()
    this.initOnScroll()

    window.requestAnimationFrame(() => {
      this.initTweener()
    })
  }

  setPlusVisibility (hidePlus = false) {
    this.element.querySelector('.homepage-tweener__plus-visual').classList.toggle('homepage-tweener__plus-visual--hidden', hidePlus)
  }

  setHeaderColor (color) {
    const header = document.querySelector('.header')
    const logo = document.querySelector('.logo')

    if (color === 'default') {
      header.classList.remove('header--white')
      logo.classList.add('logo--default')
    } else if (color === 'white') {
      header.classList.add('header--white')
      logo.classList.remove('logo--default')
    }
  }

  showMiniLogo (showMiniLogo = false) {
    document.querySelector('.logo').classList.toggle('logo--mini', showMiniLogo)
  }

  initTweener () {
    this.plusVisual = null

    //  this.scrollSpeed = 600
    this.isDesktop = !isTouchDevice()

    this.initPlusVisual()
    this.initTweens()
  }

  initOnScroll () {
    const header = document.querySelector('.header')
    const logo = document.querySelector('.logo')

    header.classList.add('header--white')
    logo.classList.remove('logo--default')

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 0) {
        header.classList.remove('header--white')
        logo.classList.add('logo--default')
      } else {
        header.classList.add('header--white')
        logo.classList.remove('logo--default')
      }

      const rotation = 180 * (window.pageYOffset / window.innerHeight)
      // console.log(rotation)
      this.plusVisual.rotation = rotation
      this.plusVisual.rotateBy(0, 0)
    })
  }

  initPlusVisual () {
    this.plusVisual = {
      element: this.element.querySelector('.homepage-tweener__plus-visual'),
      rotation: 0,

      rotateBy: (value, speed = this.scrollSpeed) => {
        // this.plusVisual.element.style.transitionDuration = `${speed}ms`
        this.plusVisual.rotation = this.plusVisual.rotation + value
        this.plusVisual.element.style.transform = `rotate(${this.plusVisual.rotation}deg)`
      }
    }
  }

  async initTweens () {
    const { gsap, CSSPlugin, ScrollTrigger } = (await import('../../../assets/scripts/plugins/gsap')).default()

    window.gsap = gsap
    window.CSSPlugin = CSSPlugin
    window.ScrollTrigger = ScrollTrigger

    window.gsap.registerPlugin(window.CSSPlugin)
    window.gsap.registerPlugin(window.ScrollTrigger)
    window.gsap.ticker.sleep() // Prevent GSAP ticker from leaking memory when not in use

    // Check for re-layout and recalculate, otherwise ScrollTrigger can lose track
    const observer = new ResizeObserver(() => window.ScrollTrigger.refresh())
    observer.observe(document.body)

    const slide2 = this.slides[1].querySelector('div')
    window.gsap.set(slide2, { force3D: true }) // We're gonna be needing the GPU real soon
    window.gsap.fromTo(slide2, { x: '25%', width: '200vw' }, { x: '-50%', scrollTrigger: { trigger: slide2.parentNode, scrub: 0.15 }, ease: 'linear' })

    const slide3 = this.slides[2].querySelector('div')
    window.gsap.set(slide3, { force3D: true }) // We're gonna be needing the GPU real soon
    window.gsap.fromTo(slide3, { y: '-25%' }, { y: '25%', scrollTrigger: { trigger: slide3.parentNode, scrub: 0.15 }, ease: 'linear' })

    // const slide4 = this.slides[3].querySelector('div')
    // window.gsap.set(slide4, { force3D: true }) // We're gonna be needing the GPU real soon
    // window.gsap.fromTo(slide4, { y: '50%' }, { y: '-50%', scrollTrigger: { trigger: slide4.parentNode, scrub: 0.15 }, ease: 'linear' })

    // const slide5 = this.slides[4].querySelector('div')
    // window.gsap.set(slide5, { force3D: true }) // We're gonna be needing the GPU real soon
    // window.gsap.fromTo(slide5, { y: '-50%' }, { y: '0', scrollTrigger: { trigger: slide5.parentNode, scrub: 0.15 }, ease: 'linear' })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.homepage-tweener').forEach(element => {
  element.instance = element.instance || new HomepageTweenerComponent(element)
}))

// OLD SWIPER FUNCTIONS
/*
    swiperOptions.on.afterInit = swiper => {
      const currentSlide = swiper.slides[swiper.activeIndex]

      this.setPlusVisibility(currentSlide.getAttribute('data-hide-plus'))
      this.setHeaderColor(currentSlide.getAttribute('data-header-color'))
      this.showMiniLogo(swiper.activeIndex !== 0)

      window.clearTimeout(this.timeout)
      this.timeout = window.setTimeout(() => {
        document.scrollingElement.classList.add('prevent-scrolling')
        this.element.dispatchEvent(new CustomEvent('swiper-initialized'))
        this.element.setAttribute('data-initialized', true)
      }, 150)
    }

    swiperOptions.on.slideChangeTransitionStart = (swiper, event) => swiper.el.classList.add('is-transitioning')

    swiperOptions.on.slideChangeTransitionEnd = swiper => {
      const currentSlide = swiper.slides[swiper.activeIndex]

      swiper.el.classList.remove('is-transitioning')

      this.setPlusVisibility(currentSlide.getAttribute('data-hide-plus'))
      this.setHeaderColor(currentSlide.getAttribute('data-header-color'))
      this.showMiniLogo(swiper.activeIndex !== 0)
    }

    swiperOptions.on.slidePrevTransitionStart = () => this.plusVisual.rotateBy(-90)
    swiperOptions.on.slideNextTransitionStart = () => this.plusVisual.rotateBy(+90)

    swiperOptions.on.slideNextTransitionEnd = swiper => {
      // if (!this.isDesktop) return

      if (swiper.activeIndex === swiper.slides.length - 1) {
        document.scrollingElement.classList.remove('prevent-scrolling')

        window.clearTimeout(this.timeout)
        this.timeout = window.setTimeout(() => {
          swiper.mousewheel.disable()
          swiper.allowTouchMove = false
          swiper.update() // Force swiper to conform to the new settings

          // Force scroll a bit - as a quick workaround to getting 'stuck' at the last slide
          window.scrollTo({ top: 100, behavior: 'smooth' })
        }, 100)
      }
    }

    swiperOptions.on.slideChange = (swiper, event) => {
      if (!this.isDesktop) return

      const subSwiper = swiper.slides[swiper.activeIndex].querySelector('.swiper')

      if (!subSwiper) {
        swiper.allowSlidePrev = true
        swiper.allowSlideNext = true
      }
    }

    this.swiper = new Swiper(this.swiperElement, swiperOptions)

    window.addEventListener('scroll', event => {
      if (window.scrollY === 0) {
        if (this.swiper && this.swiper.activeIndex === this.swiper.slides.length - 1) {
          document.scrollingElement.classList.add('prevent-scrolling')
          this.showMiniLogo(this.swiper.activeIndex !== 0)

          window.clearTimeout(this.timeout)
          this.timeout = window.setTimeout(() => {
            this.swiper.mousewheel.enable()
            this.swiper.allowTouchMove = !this.isDesktop
            this.swiper.update() // Force swiper to conform to the new settings
          }, 100)
        }

        this.setPlusVisibility(false)
      } else {
        this.setPlusVisibility(true)
      }
    })
    */
