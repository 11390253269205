import Component from '../../../assets/scripts/modules/component'

export default class PlusCornersComponent extends Component {
  init () {
    this.addListeners()
  }

  addListeners () {
    const parent = this.element.parentNode

    if (!parent) {
      return
    }

    parent.addEventListener('mouseenter', () => {
      this.element.classList.add('is-hover')
    })

    parent.addEventListener('mouseleave', () => {
      this.element.classList.remove('is-hover')
    })
  }
}

window.PlusCornersComponent = PlusCornersComponent

window.addEventListener('init-load', () => document.querySelectorAll('.plus-corners').forEach(element => {
  element.instance = element.instance || new PlusCornersComponent(element)
}))
