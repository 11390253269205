
import konamiCode from './utilities/konami-code.js'

// For Dynamic CDN Asset Path
__webpack_public_path__ = window.webpackPublicPath || '/static/scripts/' /* eslint-disable-line camelcase, no-undef */

// Import in reverse order from stylesheets
require('../../components/structures/cookie-bar/cookie-bar.js')
require('../../components/structures/document/document.js')
require('../../components/structures/skip-to-main/skip-to-main.js')
require('../../components/organisms/article-cookies/article-cookies.js')
require('../../components/organisms/carousel/carousel.js')
require('../../components/organisms/filter-bar/filter-bar.js')
require('../../components/organisms/homepage-scroller/homepage-scroller.js')
require('../../components/organisms/homepage-tweener/homepage-tweener.js')
require('../../components/organisms/overview-grid/overview-grid.js')
require('../../components/organisms/search-results/search-results.js')
require('../../components/molecules/homepage-scroller-horizontal-thumbnail-slider/homepage-scroller-horizontal-thumbnail-slider.js')
require('../../components/molecules/homepage-scroller-images-slider/homepage-scroller-images-slider.js')
require('../../components/atoms/iframe-embed/iframe-embed.js')
require('../../components/atoms/menu/menu.js')
require('../../components/atoms/picture/picture.js')
require('../../components/atoms/plus-corners/plus-corners.js')
require('../../components/atoms/search-field/search-field.js')
require('../../components/atoms/share-button/share-button.js')
require('../../components/atoms/video-embed/video-embed.js')

console.log('\n %cMade with %c♥%c by Fabrique \n', 'font: 16px serif;', 'font: 13px serif; color: #f00;', 'font: 16px serif;')

// There are 4 load events:
// - 'init-immediate': (compiles when the script is loaded; blocks rendering)
// - 'init-load': (on DOMContentLoaded event; does not block rendering)
// - 'init-after-load': (on Load event, slightly after DOMContentLoaded)
// - 'init-delayed-load': (after Load event, with a slight delay, for iframes and such)
// Usually, the 'init-load' event will suffice.
window.dispatchEvent(new CustomEvent('init-immediate'))
window.addEventListener('DOMContentLoaded', () => window.dispatchEvent(new CustomEvent('init-load')))
window.addEventListener('load', () => window.dispatchEvent(new CustomEvent('init-after-load')))
window.addEventListener('load', () => window.setTimeout(() => window.dispatchEvent(new CustomEvent('init-delayed-load')), 1000))

// Focus stuff
document.addEventListener('keydown', () => document.documentElement.classList.add('key-pressed'))
document.addEventListener('mousedown', () => document.documentElement.classList.remove('key-pressed'))
document.addEventListener('touchstart', () => document.documentElement.classList.remove('key-pressed'))

// This gives you vh-like values, but without scrollbars and addressbar and such
// This allows usage of document height / width in CSS
// NOTE: Only turn this on when needed - very heavy when resizing
// NOTE: Resize observer only from Safari 13.1 onwards, so back to window.onresize.
// new ResizeObserver(() => {
// ...
// }).observe(document.documentElement)
if (document.documentElement.classList.contains('is-ios') || document.documentElement.classList.contains('is-android')) {
  const onResizeHandler = () => {
    window.requestAnimationFrame(() => {
      document.documentElement.style.setProperty('--100iw', `${window.innerWidth}px`)
      document.documentElement.style.setProperty('--100ih', `${window.innerHeight}px`)
      document.documentElement.style.setProperty('--100ow', `${window.outerWidth}px`)
      document.documentElement.style.setProperty('--100oh', `${window.outerHeight}px`)
      document.documentElement.style.setProperty('--100vw', `${document.documentElement.clientWidth}px`)
      document.documentElement.style.setProperty('--100vh', `${document.documentElement.clientHeight}px`)
      document.documentElement.style.setProperty('--100dw', `${document.documentElement.offsetWidth}px`)
      document.documentElement.style.setProperty('--100dh', `${document.documentElement.offsetHeight}px`)
    })
  }
  window.addEventListener('resize', () => onResizeHandler())
  onResizeHandler()
}

// loading="lazy" polyfill (~3kB) - for non-Chrome
if (!('loading' in HTMLImageElement.prototype)) {
  require('loading-attribute-polyfill')
}

// Import smooth scroll (~35kB) - for Safari and Edge
if (!('scrollBehavior' in document.documentElement.style)) {
  const smoothscroll = require('smoothscroll-polyfill')
  smoothscroll.polyfill()
}

// if (window.navigator.userAgent.indexOf('Safari') !== -1) {
// Web animations polyfill ~(50kB) - for Safari and Edge
// About 50kb raw, so only use if you need it.
// const x = import('web-animations-js')
// }

// Show debug styles on L hotkey and grid on G hotkey when document is focussed.
// if (WEBPACK_ENV.ENV === 'development') {
if (window.location.href.indexOf('fabriquehq') !== -1 || window.location.href.indexOf('127.0.0.1') !== -1) {
  document.addEventListener('keydown', event => {
    if (event.target !== document.body) {
      return
    }

    if (event.key === 'l') {
      document.documentElement.classList.toggle('debug-layout')
    }

    document.cookie = `debug-layout=${document.documentElement.classList.contains('debug-layout')}; path=/;`
  })

  document.documentElement.classList.toggle('debug-layout', document.cookie.indexOf('debug-layout=true') !== -1)
}

// Confetti with plus signs
const doConfetti = function (canvas) {
  // start blatant copy paste from a codepen
  const NUM_CONFETTI = 64
  const COLORS = [
    [0, 107, 255]
  ]
  const SPEED = 0.01
  const context = canvas.getContext('2d')

  let w = canvas.width = window.innerWidth
  let h = canvas.height = window.innerHeight

  const resizeWindow = function () {
    w = canvas.width = window.innerWidth
    h = canvas.height = window.innerHeight
  }

  window.addEventListener('resize', resizeWindow.bind(this))

  window.onload = function () {
    return setTimeout(resizeWindow.bind(this), 0)
  }

  const range = function (a, b) {
    return (b - a) * Math.random() + a
  }

  const drawPlus = function (x, y, r, style) {
    const w = r
    const h = r * 5

    context.beginPath()
    context.rect(x - (w / 2), y - (h / 2), w, h)
    context.fillStyle = style
    context.fill()

    context.beginPath()
    context.rect(x - (h / 2), y - (w / 2), h, w)
    context.fillStyle = style
    context.fill()
  }

  let xpos = 0.5

  document.onmousemove = function (e) {
    xpos = e.pageX / w
  }

  const Confetti = (function () {
    function Confetti () {
      this.style = COLORS[~~range(0, COLORS.length - 1)]
      this.rgb = `rgba(${this.style[0]},${this.style[1]},${this.style[2]}`
      this.r = ~~range(2, 6)
      this.r2 = 2 * this.r
      this.replace()
    }

    Confetti.prototype.replace = function () {
      this.opacity = 0
      this.dop = SPEED * range(1, 4)
      this.x = range(-this.r2, w - this.r2)
      this.y = range(-20, h - this.r2)
      this.xmax = w - this.r
      this.ymax = h - this.r
      this.vx = range(0, 2) + 8 * xpos - 5
      this.vy = 0.7 * this.r + range(-1, 1)
    }

    Confetti.prototype.draw = function () {
      var _ref
      this.x += this.vx
      this.y += this.vy
      this.opacity += this.dop
      if (this.opacity > 1) {
        this.opacity = 1
        this.dop *= -1
      }
      if (this.opacity < 0 || this.y > this.ymax) {
        this.replace()
      }
      if (!(((_ref = this.x) > 0 && _ref < this.xmax))) {
        this.x = (this.x + this.xmax) % this.xmax
      }
      drawPlus(~~this.x, ~~this.y, this.r, `${this.rgb},${this.opacity}`)
    }

    return Confetti
  })()

  const confetti = (function () {
    let _i
    const _results = []

    for (_i = 1; NUM_CONFETTI >= 1 ? _i <= NUM_CONFETTI : _i >= NUM_CONFETTI; _i = NUM_CONFETTI >= 1 ? ++_i : --_i) {
      _results.push(new Confetti())
    }

    return _results
  })()

  const step = function () {
    let c
    let _i
    let _len

    requestAnimationFrame(step)
    context.clearRect(0, 0, w, h)

    const _results = []

    for (_i = 0, _len = confetti.length; _i < _len; _i++) {
      c = confetti[_i]
      _results.push(c.draw())
    }
    return _results
  }

  step()
}

const doKonamiCode = () => {
  const canvas = document.querySelector('#konami-canvas') || document.documentElement.appendChild(document.createElement('canvas'))

  canvas.id = 'konami-canvas'
  canvas.style.position = 'fixed'
  canvas.style.top = 0
  canvas.style.left = 0
  canvas.style.width = '100%'
  canvas.style.height = '100%'
  canvas.style.pointerEvents = 'none'
  canvas.style.background = 'transparent'
  canvas.style.zIndex = 16384

  doConfetti(canvas)
}

konamiCode(() => doKonamiCode())
