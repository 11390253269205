import Component from '../../../assets/scripts/modules/component'
import isTouchDevice from '../../../assets/scripts/utilities/is-touch-device'

export default class HomepageScrollerComponent extends Component {
  init () {
    this.timeout = null
    history.scrollRestoration = 'manual' // Force scrolling to top on load

    window.requestAnimationFrame(() => {
      this.initScroller()
    })
  }

  setPlusHidden (hidePlus = false) {
    this.element.querySelector('.homepage-scroller__plus-visual').classList.toggle('homepage-scroller__plus-visual--hidden', hidePlus)
  }

  setHeaderColor (color) {
    const header = document.querySelector('.header')
    const logo = document.querySelector('.logo')
    const pagination = this.element.querySelector('.homepage-scroller__pagination')

    if (color === 'default') {
      header.classList.remove('header--white')
      logo.classList.add('logo--default')
      pagination.classList.remove('homepage-scroller__pagination--white')
    } else if (color === 'white') {
      header.classList.add('header--white')
      logo.classList.remove('logo--default')
      pagination.classList.add('homepage-scroller__pagination--white')
    }
  }

  showMiniLogo (showMiniLogo = false) {
    document.querySelector('.logo').classList.toggle('logo--mini', showMiniLogo)
  }

  hideMiniLogo (hide = false) {
    document.querySelector('.logo').classList.toggle('logo--hidden', hide)
  }

  initScroller () {
    this.swiper = null
    this.plusVisual = null

    this.scrollSpeed = 600
    this.isDesktop = !isTouchDevice()
    this.swiperElement = this.element.querySelector('.swiper')

    this.initPlusVisual()
    this.initSwiper()
  }

  initPlusVisual () {
    this.plusVisual = {
      element: this.element.querySelector('.homepage-scroller__plus-visual'),
      rotation: 0,

      rotateBy: (value, speed = this.scrollSpeed) => {
        this.plusVisual.element.style.transitionDuration = `${speed}ms`
        this.plusVisual.rotation = this.plusVisual.rotation + value
        this.plusVisual.element.style.transform = `rotate(${this.plusVisual.rotation}deg)`
      }
    }
  }

  async initSwiper () {
    const lazySwiper = (await import('../../../assets/scripts/plugins/swiper')).default()
    const Swiper = lazySwiper.Swiper

    if (!Swiper) return

    const swiperOptions = {
      modules: [lazySwiper.Controller, lazySwiper.Navigation, lazySwiper.Pagination, lazySwiper.Mousewheel, lazySwiper.Keyboard],
      spaceBetween: 1, // weird bug, has to be at least 1 ?
      direction: 'vertical',
      speed: this.scrollSpeed,
      preventInteractionOnTransition: true,
      mousewheel: { thresholdTime: 100, releaseOnEdges: false },
      keyboard: { enabled: true, onlyInViewport: false },
      allowTouchMove: !this.isDesktop,
      pagination: {
        el: this.element.querySelector('.homepage-scroller__pagination'),
        clickable: false,
        renderBullet (index, className) {
          return `<span data-index="${index}" class="${className}"></span>`
        }
      },
      on: {}
    }

    swiperOptions.on.afterInit = (swiper) => {
      const currentSlide = swiper.slides[swiper.activeIndex]

      this.setPlusHidden(currentSlide.getAttribute('data-hide-plus'))
      this.setHeaderColor(currentSlide.getAttribute('data-header-color'))
      this.showMiniLogo(swiper.activeIndex !== 0)

      window.clearTimeout(this.timeout)
      this.timeout = window.setTimeout(() => {
        document.scrollingElement.classList.add('prevent-scrolling')
        this.element.dispatchEvent(new CustomEvent('swiper-initialized'))
        this.element.setAttribute('data-initialized', true)
      }, 150)
    }

    swiperOptions.on.slideChangeTransitionStart = (swiper, event) => swiper.el.classList.add('is-transitioning')

    swiperOptions.on.slideChangeTransitionEnd = (swiper) => {
      const currentSlide = swiper.slides[swiper.activeIndex]

      swiper.el.classList.remove('is-transitioning')
      this.setPlusHidden(currentSlide.getAttribute('data-hide-plus'))
      this.setHeaderColor(currentSlide.getAttribute('data-header-color'))
      this.showMiniLogo(swiper.activeIndex !== 0)
      this.hideMiniLogo(currentSlide.getAttribute('data-hide-mini-logo'))
    }

    swiperOptions.on.slidePrevTransitionStart = () => this.plusVisual.rotateBy(-90)
    swiperOptions.on.slideNextTransitionStart = () => this.plusVisual.rotateBy(+90)

    swiperOptions.on.slideChange = (swiper, event) => {
      if (!this.isDesktop) return

      const subSwiper = swiper.slides[swiper.activeIndex].querySelector('.swiper')

      if (!subSwiper) {
        swiper.allowSlidePrev = true
        swiper.allowSlideNext = true
      }
    }

    this.swiper = new Swiper(this.swiperElement, swiperOptions)
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.homepage-scroller').forEach((element) => {
    element.instance = element.instance || new HomepageScrollerComponent(element)
  })
)
