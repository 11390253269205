// jshint module: true

import { parseDomain, ParseResultType } from 'parse-domain'

export function getCookie (name) {
  const parts = `; ${document.cookie}`.split(`; ${name}=`)
  return parts.length < 2 ? undefined : parts.pop().split(';').shift()
}

export function setCookie (name, value, expiryDays, domain = '*', path = '/') {
  const expiryDate = new Date(new Date().setDate(new Date().getDate() + (expiryDays || 365))).toUTCString()
  const cookie = [`${name}=${value}`]

  cookie.push(`Expires=${expiryDate}`)
  cookie.push(`Path=${path}`)

  if (domain === '*') {
    // Wildcard doesn't always work correctly, and figuring out of the hostname is a subdomain or not is... tricky. -EKL
    const parseResult = parseDomain(window.location.hostname)

    if (parseResult.type === ParseResultType.Listed) {
      cookie.push(`Domain=${parseResult.domain}.${parseResult.topLevelDomains.join('.')}`)
    } else {
      // Do nothing
    }
  } else {
    cookie.push(`Domain=${location.hostname}`)
  }

  if (window.location.protocol === 'https:') {
    cookie.push(`Secure`)
  }

  cookie.push(`SameSite=Lax`)

  document.cookie = cookie.join('; ').trim()
}
