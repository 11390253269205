import Component from '../../../assets/scripts/modules/component'
import { getCookie } from '../../../assets/scripts/utilities/simple-cookies'

export default class MenuComponent extends Component {
  init () {
    this.preventHash()
    this.initLanguageSelectForm()
    // this.initMenuButtonOffsetColor()
    // this.initMenuButtonPeekaboo()
  }

  preventHash () {
    const nav = this.element.querySelector('#menu')
    const underlayer = this.element.querySelector('#menu-underlayer')
    const buttons = [...this.element.querySelectorAll('.button--menu, .button--close')]

    const onClickHandler = (event) => {
      event.preventDefault()
      nav.classList.toggle('target')
      underlayer.classList.toggle('is-active')
    }

    if (underlayer) {
      underlayer.addEventListener('click', onClickHandler)
    }

    buttons.forEach((button) => button.addEventListener('click', (event) => onClickHandler(event)))
  }

  initLanguageSelectForm () {
    this.languageAnchors = [...this.element.querySelectorAll('.menu__language-item-anchor[data-value]')]
    this.languageAnchors.forEach((anchor) => anchor.addEventListener('click', (event) => this.onLanguageAnchorClickHandler(event, anchor)))
  }

  async onLanguageAnchorClickHandler (event, element) {
    const value = element.getAttribute('data-value') || ''
    const csrfToken = getCookie('csrftoken')

    if (value && csrfToken) {
      const formData = new FormData()
      formData.append('language', value)
      formData.append('csrfmiddlewaretoken', csrfToken)
      await fetch('/setlang/', { method: 'POST', cache: 'no-cache', credentials: 'include', redirect: 'follow', body: formData }).catch((error) =>
        console.error(error)
      )
      // Don't do anything, assume success
    }
  }

  initMenuButtonOffsetColor () {
    const button = this.element.querySelector('.button--menu')

    let isBusy = false
    let scrollY
    let timeout

    const onScrollHandler = () => {
      if (isBusy) return

      isBusy = true
      scrollY = window.scrollY || window.pageYOffset

      if (scrollY > button.getBoundingClientRect().height) {
        window.clearTimeout(timeout)
        timeout = window.setTimeout(function () {
          button.classList.add('is-outside-visual')
          window.clearTimeout(timeout)
          isBusy = false
        }, 100) // Peekaboo delay
      } else {
        window.clearTimeout(timeout)
        timeout = window.setTimeout(function () {
          button.classList.remove('is-outside-visual')
          window.clearTimeout(timeout)
          isBusy = false
        }, 100) // Peekaboo delay
      }
    }

    window.addEventListener('scroll', () => onScrollHandler())
    onScrollHandler()
  }

  initMenuButtonPeekaboo () {
    const button = this.element.querySelector('.button--menu')

    let isBusy = false
    let oldScrollY = window.scrollY || window.pageYOffset
    let scrollY
    let timeout

    const onScrollHandler = () => {
      if (isBusy) return

      isBusy = true
      scrollY = window.scrollY || window.pageYOffset

      if (scrollY < 10 || (scrollY <= oldScrollY && scrollY > button.getBoundingClientRect().height)) {
        window.clearTimeout(timeout)
        timeout = window.setTimeout(function () {
          button.classList.remove('is-outofview')
          isBusy = false
        }, 100) // Peekaboo delay
      } else {
        window.clearTimeout(timeout)
        timeout = window.setTimeout(function () {
          button.classList.add('is-outofview')
          isBusy = false
        }, 100) // Peekaboo delay
      }

      oldScrollY = scrollY
      isBusy = false
    }

    window.addEventListener('scroll', () => onScrollHandler())
    onScrollHandler()
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.menu').forEach((element) => {
    element.instance = element.instance || new MenuComponent(element)
  })
)
