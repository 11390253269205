
import Component from '../../../assets/scripts/modules/component'

class SearchFieldComponent extends Component {
  init () {
    this.input = this.element.querySelector('.search-field__input')

    if (!this.input) {
      return
    }

    this.addClearHandler()
    this.checkInput('has-value')

    this.input.addEventListener('keyup', () => this.checkInput('has-input'))
  }

  addClearHandler () {
    const clearButton = this.element.querySelector('.search-field__button--clear')

    if (!clearButton) {
      return
    }

    clearButton.addEventListener('click', event => {
      event.preventDefault()

      this.input.value = ''
      this.element.classList.remove('has-input')
      this.element.classList.remove('has-value')
    })
  }

  checkInput (className) {
    this.element.classList.toggle(className, this.input.value !== '')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.search-field').forEach(element => {
  element.instance = element.instance || new SearchFieldComponent(element)
}))
