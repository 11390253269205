
import Component from '../../../assets/scripts/modules/component'
import isTouchDevice from '../../../assets/scripts/utilities/is-touch-device'

export default class HomepageScrollerHorizontalThumbnailSliderComponent extends Component {
  init () {
    this.initScroller()
  }

  initScroller () {
    this.scrollSpeed = 300

    this.timeout = null
    this.swiper = null
    this.isDesktop = !isTouchDevice()
    this.mainSwiper = this.element.closest('.homepage-scroller')
    this.useNavigationButtons = !!this.element.dataset.useNavigationButtons

    if (this.mainSwiper) {
      this.mainSwiper.addEventListener('swiper-initialized', this.initSwiper.bind(this), false)
    } else {
      this.initSwiper()
    }
  }

  async initSwiper () {
    const lazySwiper = (await import('../../../assets/scripts/plugins/swiper')).default()
    const Swiper = lazySwiper.Swiper

    if (!Swiper) return

    const horizontalSwiperOptions = {
      modules: [lazySwiper.Controller, lazySwiper.Navigation, lazySwiper.Pagination, lazySwiper.Mousewheel, lazySwiper.Keyboard],
      direction: 'horizontal',
      speed: this.scrollSpeed,
      mousewheel: { thresholdTime: 1 },
      keyboard: { enabled: true, onlyInViewport: true },
      slidesPerView: 'auto',
      spaceBetween: 30,
      centeredSlides: true,
      allowTouchMove: !this.isDesktop,
      on: {}
    }

    if (this.useNavigationButtons) {
      horizontalSwiperOptions.navigation = { enabled: true, nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }
      horizontalSwiperOptions.allowTouchMove = true
      horizontalSwiperOptions.mousewheel = false
      // horizontalSwiperOptions.breakpoints = {
      //  1180: { initialSlide: 1 },
      //  2260: { initialSlide: 2 }
      // }
      horizontalSwiperOptions.loop = true
      horizontalSwiperOptions.duplicateSlideAmount = 4
    } else {
      if (this.mainSwiper) {
        horizontalSwiperOptions.on.scroll = swiper => {
          this.mainSwiper.instance.swiper.allowSlidePrev = false
          this.mainSwiper.instance.swiper.allowSlideNext = false

          window.clearTimeout(this.timeout)
          this.timeout = window.setTimeout(() => {
            if (this.swiper.activeIndex === 0) {
              this.mainSwiper.instance.swiper.allowSlidePrev = true
              this.mainSwiper.instance.swiper.slideTo(this.mainSwiper.instance.swiper.activeIndex) // Force swiper to enable back
            }

            if (this.swiper.activeIndex === this.swiper.slides.length - 1) {
              this.mainSwiper.instance.swiper.allowSlideNext = true
            }
          }, this.scrollSpeed + 100)
        }
      }
    }

    if (this.mainSwiper) {
      horizontalSwiperOptions.on.slidePrevTransitionStart = () => this.mainSwiper.instance.plusVisual.rotateBy(-90, this.scrollSpeed)
      horizontalSwiperOptions.on.slideNextTransitionStart = () => this.mainSwiper.instance.plusVisual.rotateBy(+90, this.scrollSpeed)
    }

    this.swiper = new Swiper(this.element.querySelector('.swiper'), horizontalSwiperOptions)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.homepage-scroller-horizontal-thumbnail-slider').forEach(element => {
  element.instance = element.instance || new HomepageScrollerHorizontalThumbnailSliderComponent(element)
}))
