import Component from '../../../assets/scripts/modules/component'

const SWIPER_OPTIONS = {
  autoPlay: false,
  autoHeight: false,
  controlBy: 'container',
  direction: 'horizontal',
  effect: 'slide',
  grabCursor: true,
  initialSlide: 0,
  loop: false,
  loopAdditionalSlides: 4,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  pagination: { el: '.swiper-pagination', type: 'bullets', bulletElement: 'span', clickable: true },
  paginationClickable: true,
  preloadImages: false,
  resistanceRatio: 0,
  roundLengths: true,
  slidesPerView: 1,
  spaceBetween: 0,
  threshold: 5,
  touchRatio: 1,
  watchOverflow: true
}

export default class CarouselComponent extends Component {
  init () {
    this.initSwiper()
  }

  async initSwiper () {
    const lazySwiper = (await import('../../../assets/scripts/plugins/swiper')).default()
    const Swiper = lazySwiper.Swiper

    if (!Swiper) return

    const container = this.element.querySelector('.swiper-container')
    const animated = (this.element.getAttribute('data-carousel-animated') === 'true')
    const looping = (this.element.getAttribute('data-carousel-looping') === 'true')
    const crossfade = (this.element.getAttribute('data-carousel-crossfade') === 'true')
    const options = {
      ...SWIPER_OPTIONS,
      modules: [lazySwiper.Controller, lazySwiper.Navigation, lazySwiper.Pagination, lazySwiper.A11y, lazySwiper.EffectFade],
      speed: (animated || crossfade) ? 300 : 0,
      loop: looping,
      // fading: crossfade ? { crossFade: true } : false,
      effect: crossfade ? 'fade' : 'slide'
    }
    if (crossfade) {
      options.fadeEffect = { crossfade }
    }
    this.element.swiperInstance = new Swiper(container, options)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.carousel').forEach(element => {
  element.instance = element.instance || new CarouselComponent(element)
}))
