import Component from '../../../assets/scripts/modules/component'

export default class SearchResultsComponent extends Component {
  init () {
    this.input = this.element.querySelector('input')
    this.grid = this.element.querySelector('.search-results__grid')

    if (!this.input || !this.grid) return

    if (this.input.value) {
      const rect = this.grid.getBoundingClientRect()

      window.scrollTo({ top: rect.top + rect.height + window.pageYOffset, behavior: 'smooth' })
    } else {
      this.input.focus()
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.search-results').forEach(element => {
  element.instance = element.instance || new SearchResultsComponent(element)
}))
