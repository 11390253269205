import Component from '../../../assets/scripts/modules/component'

function checkFilterParam () {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search.slice(1))
  const filterParam = params.get('isFilterbarOpen')

  if (!filterParam || filterParam === 'false') {
    return false
  } else if (filterParam === 'true') {
    return true
  }
}

export default class FilterbarComponent extends Component {
  init () {
    this.checkUrl()
    this.initFilterListsToggle()
  }

  checkUrl () {
    if (checkFilterParam()) {
      this.element.classList.add('is-open')
    }
  }

  initFilterListsToggle () {
    const toggleBtn = this.element.querySelector('.filter-bar__toggle')

    const clickHandler = (e) => {
      e.preventDefault()
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search.slice(1))

      if (checkFilterParam()) {
        params.set('isFilterbarOpen', false)
        this.element.classList.remove('is-open')
      } else {
        params.set('isFilterbarOpen', true)
        this.element.classList.add('is-open')
      }

      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params}${window.location.hash}`
      )
    }

    toggleBtn.addEventListener('click', clickHandler)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.filter-bar').forEach(element => {
  element.instance = element.instance || new FilterbarComponent(element)
}))
