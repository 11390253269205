import Component from '../../../assets/scripts/modules/component'

export default class DocumentComponent extends Component {
  async init () {
    this.initExternalAnchors()
    this.initMenuToggleOnScroll()
  }

  initExternalAnchors () {
    const anchors = [
      ...document.querySelectorAll(
        'a[href^="//"]:not([href*="hnsland.nl"]):not([href*="hns.fabriquehq.nl"]):not([href*="localhost"]):not([href*="127.0.0.1"]), a[href^="http://"]:not([href*="hnsland.nl"]):not([href*="hns.fabriquehq.nl"]):not([href*="localhost"]):not([href*="127.0.0.1"]), a[href^="https://"]:not([href*="hnsland.nl"]):not([href*="hns.fabriquehq.nl"]):not([href*="localhost"]):not([href*="127.0.0.1"])'
      )
    ]

    anchors.forEach((anchor) => anchor.setAttribute('target', 'blank'))
  }

  showMiniLogo (showMiniLogo = false) {
    document.querySelector('.logo')?.classList.toggle('logo--mini', showMiniLogo)
  }

  stickyMenuBar (sticky = false) {
    if (document.querySelector('.homepage-scroller')) {
      document.querySelector('.header')?.classList.add('header--homepage')
    }

    document.querySelector('.header')?.classList.toggle('header--sticky', sticky)
  }

  onScrollHandler () {
    const hasScrolled = window.pageYOffset > 100

    this.showMiniLogo(hasScrolled)
    this.stickyMenuBar(hasScrolled)
    document.documentElement.classList.toggle('has-scrolled', hasScrolled)
  }

  initMenuToggleOnScroll () {
    window.addEventListener('scroll', () => this.onScrollHandler())
    this.onScrollHandler()
  }
}

window.addEventListener('init-immediate', () => {
  document.documentElement.instance = new DocumentComponent(document.documentElement)
})
