
import Component from '../../../assets/scripts/modules/component'
import { getCookie } from '../../../assets/scripts/utilities/simple-cookies'

export default class IFrameEmbedComponent extends Component {
  init () {
    this.iframe = this.element.querySelector('.iframe-embed__iframe')

    if (!this.iframe) {
      return
    }

    const cookie = getCookie('cookie-consent')

    if (!cookie || cookie.split(',').indexOf('embeds') === -1) {
      this.element.classList.add('iframe-embed--cookies-not-accepted')
      window.addEventListener('cookie-consent', () => this.initIFrame())
    } else {
      this.element.classList.remove('iframe-embed--cookies-not-accepted')
      this.initIFrame()
    }
  }

  initIFrame () {
    this.iframe.setAttribute('src', this.iframe.getAttribute('data-src'))
    this.iframe.setAttribute('data-src', '')
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.iframe-embed')) {
    element.instance = element.instance || new IFrameEmbedComponent(element)
  }
})
