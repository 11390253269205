
import Component from '../../../assets/scripts/modules/component'

export default class SkipToMainComponent extends Component {
  init () {
    this.button = this.element.querySelector('.button')
    this.button.addEventListener('click', event => this.clickHandler(event))
  }

  clickHandler (event) {
    let target = document.querySelector('h1')

    if (!target) {
      target = document.querySelector('main, role[main]')
    }

    if (!target) {
      return
    }

    window.requestAnimationFrame(() => {
      const rect = target.getBoundingClientRect()

      window.scroll({ left: 0, top: rect.y, behavior: 'smooth' })

      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      this.button.blur()

      if (target.getAttribute('tabindex') === null) {
        target.setAttribute('tabindex', 0)
        target.focus()
      }
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.skip-to-main').forEach(element => {
  element.instance = element.instance || new SkipToMainComponent(element)
}))
