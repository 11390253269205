import Component from '../../../assets/scripts/modules/component'

export default class HomepageScrollerImagesSliderComponent extends Component {
  init () {
    this.initSlides()
  }

  initSlides () {
    this.timeout = null
    this.animationTimeout = null

    this.titlesTop = [...this.element.querySelectorAll('.homepage-scroller-images-slider__titles-top .homepage-scroller-images-slider__title')]
    this.titlesBottom = [...this.element.querySelectorAll('.homepage-scroller-images-slider__titles-bottom .homepage-scroller-images-slider__title')]
    this.slides = [...this.element.querySelectorAll('.homepage-scroller-images-slider__image')]
    this.links = [...this.element.querySelectorAll('.homepage-scroller-images-slider__link')]

    this.slideIntervalInMs = parseFloat(this.element.getAttribute('data-slide-interval-ms')) || 5000
    this.slideInitDelay = 0
    this.currentIndex = this.slides.length - 1

    this.setCurrentSlide(this.currentIndex)
    this.setCurrentTitle(this.currentIndex)
    this.setCurrentLink(this.currentIndex)

    window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(() => {
      this.changeSlides()
    }, this.slideIntervalInMs)
  }

  setCurrentLink (index) {
    const prevLink = this.element.querySelector('.homepage-scroller-images-slider__link.is-active')

    if (prevLink) {
      prevLink.classList.remove('is-active')
    }

    this.links[this.getNextIndex(index)].classList.add('is-active')
  }

  setCurrentTitle (index) {
    this.titlesTop[index].classList.add('is-previous')
    this.titlesBottom[index].classList.add('is-previous')

    this.titlesTop[this.getNextIndex(index)].classList.add('is-current')
    this.titlesBottom[this.getNextIndex(index)].classList.add('is-current')
  }

  setCurrentSlide (index) {
    this.slides[index].classList.add('is-previous')
    this.slides[this.getNextIndex(index)].classList.add('is-current')

    window.clearTimeout(this.animationTimeout)
    this.animationTimeout = window.setTimeout(() => {
      this.slides[this.getNextIndex(index)].classList.add('is-animating')
    }, this.slideInitDelay)
  }

  getNextIndex (index) {
    return index + 1 > this.slides.length - 1 ? 0 : index + 1
  }

  changeSlides () {
    this.element.setAttribute('data-initialized', true)

    const prevPrev = this.currentIndex
    const prevCur = this.getNextIndex(this.currentIndex)

    if (this.currentIndex + 1 > this.slides.length - 1) {
      this.currentIndex = 0
    } else {
      this.currentIndex++
    }

    // activate new slide
    this.setCurrentSlide(this.currentIndex)
    this.setCurrentTitle(this.currentIndex)
    this.setCurrentLink(this.currentIndex)

    // deactivate prev image
    this.slides[prevPrev].classList.remove('is-previous')
    this.slides[prevCur].classList.remove('is-current')

    window.clearTimeout(this.animationTimeout)
    this.animationTimeout = setTimeout(() => {
      this.slides[this.getNextIndex(this.currentIndex)].classList.add('is-animating')
      this.slides[prevCur].classList.remove('is-animating')
    }, this.slideInitDelay)

    // deactivate prev titles
    this.titlesBottom[prevPrev].classList.remove('is-previous')
    this.titlesBottom[prevCur].classList.remove('is-current')
    this.titlesTop[prevPrev].classList.remove('is-previous')
    this.titlesTop[prevCur].classList.remove('is-current')

    window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(this.changeSlides.bind(this), this.slideIntervalInMs)
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.homepage-scroller-images-slider').forEach((element) => {
    element.instance = element.instance || new HomepageScrollerImagesSliderComponent(element)
  })
)
