import Component from '../../../assets/scripts/modules/component'

export default class OverviewGridComponent extends Component {
  init () {
    this.initInfiniteScroll()
  }

  initInfiniteScroll () {
    if (!this.element.classList.contains('overview-grid--infinite-scroll')) return

    let start = this.element.children.length
    const count = this.element.children.length
    const limitCount = 0

    let isLoading = false

    const onScrollHandler = () => {
      if (!this.element.classList.contains('overview-grid--infinite-scroll')) return

      const rect = this.element.getBoundingClientRect()
      const containerPosition = rect.top + rect.height

      let search = window.location.search || '?'

      if (isLoading) return
      if (containerPosition > (window.scrollY || window.pageYOffset) + window.innerHeight * 2) return
      if (limitCount && start * count > limitCount) return

      isLoading = true
      search = search.substring(1, search.length)
      const endpoint = `${window.location.protocol}//${window.location.host}${window.location.pathname}?start=${start}&nr=${count}&${search}`

      // // Different endpoint for static
      // if (window.location.href.indexOf("3000") !== -1 || window.location.href.indexOf("static.") !== -1) {
      //   endpoint = "/other/overview-grid-data.html";
      //   limitCount = 4;
      // }

      const result = window
        .fetch(endpoint)
        .then(
          (response) => {
            /* eslint promise/prefer-await-to-then: 0 */
            if (response.ok) {
              return response.text()
            }
            throw new Error('Request failed!')
          },
          (networkError) => {
            console.log(networkError.message)
          }
        )
        .then((data) => {
          data = data.trim()

          if (!data.length) {
            this.element.classList.remove('overview-grid--infinite-scroll')
          } else {
            if (!this.element.parentNode.classList.contains('overview-grid-container')) {
              return
            }

            const container = this.element.parentNode
            const cloneHtml = document.createElement('div')
            cloneHtml.innerHTML = data

            if (container) {
              container.append(cloneHtml.querySelector('.overview-grid'))
            }

            start += count

            const pictures = [...container.querySelectorAll('.picture')]
            const plusses = [...container.querySelectorAll('.plus-corners')]

            pictures.forEach((picture) => {
              picture.instance = picture.instance || new window.PictureComponent(picture)
            })

            plusses.forEach((plusCorners) => {
              plusCorners.instance = plusCorners.instance || new window.PlusCornersComponent(plusCorners)
            })
          }

          isLoading = false
          return true
        })

      return result
    }

    window.addEventListener('scroll', (event) => onScrollHandler(event))
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.overview-grid').forEach((element) => {
    element.instance = element.instance || new OverviewGridComponent(element)
  })
)
